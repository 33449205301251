

















































import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { formatNumber, isValid } from "../utils";
import LocalScan from "../components/LocalScan.vue";
import NumverifyScan from "../components/NumverifyScan.vue";
import GoogleSearch from "../components/GoogleSearch.vue";
import OVHScan from "../components/OVHScan.vue";
import { AxiosResponse } from "axios";

interface Data {
  loading: boolean;
  inputNumber: string;
  scanEvent: Vue;
}

export type ScanResponse<T> = AxiosResponse<{
  success: boolean;
  result: T;
  error: string;
}>;

export default Vue.extend({
  components: { LocalScan, GoogleSearch, NumverifyScan, OVHScan },
  computed: {
    ...mapState(["number"]),
    ...mapMutations(["pushError"]),
  },
  data(): Data {
    return {
      loading: false,
      inputNumber: "",
      scanEvent: new Vue(),
    };
  },
  methods: {
    clearData() {
      this.scanEvent.$emit("clear");
      this.$store.commit("resetState");
    },
    async runScans(): Promise<void> {
      if (!isValid(this.inputNumber)) {
        this.$store.commit("pushError", { message: "Number is not valid." });
        return;
      }

      this.loading = true;

      this.$store.commit("setNumber", formatNumber(this.inputNumber));

      this.scanEvent.$emit("scan");

      this.scanEvent.$on("finished", () => {
        this.loading = false;
      });
    },
    onSubmit(evt: Event) {
      evt.preventDefault();
    },
  },
});
