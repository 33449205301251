

























































































import Vue from "vue";
import { mapState } from "vuex";
import config from "@/config";
import axios, { AxiosResponse } from "axios";

type HealthResponse = { success: boolean; version: string };

export default Vue.extend({
  data: () => ({ config, version: "" }),
  computed: {
    ...mapState(["number", "errors"]),
  },
  async created() {
    const res: AxiosResponse<HealthResponse> = await axios.get(config.apiUrl);

    this.version = res.data.version;
  },
});
